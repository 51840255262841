import { Route, Routes, Navigate } from "react-router-dom";
import "./App.scss";
import Main from "./pages/main/Main";
import Login from "./pages/login/Login";
import Sites from "./pages/sites/Sites";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/sites" />} />
        <Route path="/:id" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sites" element={<Sites />} />
      </Routes>
    </>
  );
}

export default App;