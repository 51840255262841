import './main.css';
import { ArrowLeftEndOnRectangleIcon, HomeIcon, PlusCircleIcon, WindowIcon, } from "@heroicons/react/24/outline";
import { HandThumbUpIcon, TrashIcon } from "@heroicons/react/24/solid"
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sites from '../data/sites';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Main() {
  const [site, setSite] = useState('');
  const { id } = useParams()
  const [comments, setComments] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Добавляем состояние для отслеживания загрузки данных


  useEffect(() => {
    const foundSite = sites.find(site => site.id === parseInt(id));
    if (foundSite) {
      setSite(foundSite);
    }

    console.log(foundSite);
  }, [id]);


  useEffect(() => {
    // Проверяем, есть ли токен в локальном хранилище
    const token = localStorage.getItem('token');

    fetch('https://alfaapi-a6ba38f16d4e.herokuapp.com/check-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    })
      .then(response => response.json())
      .then(data => {
        if (data.isValidToken) {
          setLoading(false);
        } else {
          window.location.href = '/login';
        }
      })
      .catch(error => {
        console.error('Error:', error);
      })
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };


  useEffect(() => {
    fetch(`https://alfaapi-a6ba38f16d4e.herokuapp.com/get-comments/${id}`)
      .then(response => response.json())
      .then(data => {
        setComments(data)
        console.log(data);
      })
      .catch(error => console.error('Ошибка при загрузке комментариев:', error));
  }, []);


  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);

    // Получаем компоненты даты и времени
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // добавляем ведущий ноль, если месяц состоит из одной цифры
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Формируем строку в нужном формате
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  }

  const poolComments = comments.filter(comment => comment.approved === 0);
  const approvedComments = comments.filter(comment => comment.approved === 1);


  function deleteCooment(commentId) {
    console.log(id);
    console.log(commentId);
    fetch('https://alfaapi-a6ba38f16d4e.herokuapp.com/delete-comment/' + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token') // Передача токена в заголовке запроса
      },
      body: JSON.stringify({ commentId: commentId }) // Передача айди комментария в теле запроса
    })
      .then(response => response.json())
      .then(data => {
        console.log(data); // Вывод ответа сервера в консоль
        window.location.reload()
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }


  function approveCooment(commentId) {
    console.log(id);
    console.log(commentId);
    fetch('https://alfaapi-a6ba38f16d4e.herokuapp.com/approve-comment/' + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token') // Передача токена в заголовке запроса
      },
      body: JSON.stringify({ commentId: commentId }) // Передача айди комментария в теле запроса
    })
      .then(response => response.json())
      .then(data => {
        console.log(data); // Вывод ответа сервера в консоль
        window.location.reload()
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }


  const [dates, setDates] = useState(poolComments.map(() => null)); // Создаем массив состояний для дат

  const handleDateChange = (index, date) => {
    const newDates = [...dates];
    newDates[index] = date;
    setDates(newDates);
  };
  const handleSubmit = async (commentId, index) => {
    try {
      // Отправка запроса на сервер с выбранной датой
      await fetch(`https://alfaapi-a6ba38f16d4e.herokuapp.com/update-date/${id}/${commentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newDate: dates[index] }),
      });
      // Перезагрузка страницы после успешного запроса
      window.location.reload();
    } catch (error) {
      console.error('Error updating date:', error);
    }
  };


  return (
    <>
      {loading ? (
        <div className="flex h-[100vh] justify-center items-center">Loading...</div>
      ) : (
        <div className='content'>
          <div className='hidden bg-[#0000002] backdrop-blur-sm w-full h-[100vh] absolute'>
            <div id='close' className='absolute right-10 top-10 bg-red'>
              <PlusCircleIcon className='h-16 w-16 transform rotate-45' />
            </div>
          </div>
          <div className='flex bg-gray-900'>
            <div className='w-1/5 bg-gray-800 border border-gray-700 shadow-xl h-[100vh] flex flex-col  p-10 justify-between items-center'>
              <h1 className='text-white font-bold'>
                ADMIN<span className='text-primary-600'>PANEL</span>
              </h1>
              <ul className='text-white flex flex-col gap-4'>
                <li>
                  <a className='hover:text-primary-600 flex gap-2' href='/sites'>
                    <HomeIcon className='w-6 h-6' />
                    Home
                  </a>
                </li>
                <li>
                  <a className='hover:text-primary-600 flex gap-2' href='/sites'>
                    <WindowIcon className='w-6 h-6' />
                    Sites ({sites.length})
                  </a>
                </li>
              </ul>
              <ul className='text-white flex flex-col gap-3'>
                <li>
                  <a onClick={handleLogout} className='hover:text-primary-600 gap-2 flex items-center' href='#'>
                    Выход
                    <ArrowLeftEndOnRectangleIcon className='w-5 h-5' />
                  </a>
                </li>
              </ul>
            </div>
            <div className='w-4/5 text-white overflow-auto h-[100vh] p-10 gap-4 grid grid-cols-3 rounded-xl'>

              <div className='shadow-2xl p-3 flex bg-gray-800 border border-gray-700 rounded-xl h-min justify-between'>

                <div>
                  <h2 className='font-bold mb-4'>Current website:</h2>
                  <h2 className='font-semibold text-[22px]'><span></span>{site.name}</h2>
                  <a className='text-primary-600 text-[14px] underline' href={`https://${site.link}`}>{site.link}</a>
                </div>
                <div className='flex gap-2'>
                  <div className='bg-[#f20000] w-10 h-10 flex justify-center items-center text-white font-bold rounded-full'>
                    {poolComments.length}
                  </div>
                  <div className='w-10 h-10 flex justify-center items-center text-balck bg-gray-700 font-bold rounded-full'>
                    {approvedComments.length}
                  </div>
                </div>
              </div>
              <div className='shadow-2xl p-3 flex  justify-between'>
                <div className='w-full'>
                  <h2 className='font-bold mb-4'>Moderation</h2>
                  <div className='flex gap-2 flex-col'>
                    {poolComments.map((comment, index) => (
                      <div key={comment.id} className='shadow-2xl bg-white text-black rounded-xl border-[1px] p-2'>
                        <div className='flex w-full justify-between'>
                          <div>
                            <h3 className='font-bold'>{comment.name}</h3>
                            <div>
                              <h3 className='font-regular text-gray text-[12px]'>{
                                formatDate(comment.createdAt)}
                              </h3>
                              <div className='flex items-center mb-4 gap-2'>
                                <DatePicker
                                  selected={dates[index]}
                                  onChange={(date) => handleDateChange(index, date)}
                                  dateFormat="yyyy-MM-dd HH:mm"
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  className='border border-black p-2 rounded-lg'
                                  timeIntervals={15} // интервал выбора времени
                                  placeholderText="Выберите дату и время"
                                />
                                <button className=' text-white rounded-lg bg-blue-600 p-2' onClick={() => {
                                  handleSubmit(comment.id, index)
                                }}>Done</button>
                              </div>
                            </div>
                          </div>

                          <div className='flex gap-2'>


                            <div className='flex gap-1'>
                              <TrashIcon
                                onClick={() => {
                                  deleteCooment(comment.id)
                                }}
                                className='h-4 w-4 text-[#f20000] cursor-pointer'
                              />
                              <HandThumbUpIcon
                                onClick={() => {
                                  approveCooment(comment.id)
                                }}
                                className='h-4 w-4 cursor-pointer'
                              />
                            </div>
                          </div>
                        </div>
                        <p>{comment.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='shadow-2xl p-3 flex  justify-between'>
                <div className='w-full'>
                  <h2 className='font-bold mb-4'>На сайте</h2>
                  <div className='flex gap-2 flex-col'>
                    {approvedComments.map(comment => (
                      <div key={comment.id} className='shadow-2xl rounded-xl bg-white text-black border-[1px] p-2'>
                        <div className='flex w-full  justify-between'>
                          <h3 className='font-bold'>{comment.name}</h3>
                          <div className='flex gap-2'>
                            <h3 className='font-regular text-gray text-[12px]'>{
                              formatDate(comment.createdAt)}
                            </h3>
                            <div className='flex gap-1'>
                              <TrashIcon
                                onClick={() => {
                                  deleteCooment(comment.id)
                                }}
                                className='h-4 w-4 text-[#f20000] cursor-pointer'
                              />
                            </div>
                          </div>
                        </div>
                        <p>{comment.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
    </>

  );
}

export default Main;