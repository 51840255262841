
import { ArrowLeftEndOnRectangleIcon, PlusCircleIcon, WindowIcon, HomeIcon, Cog8ToothIcon, PencilIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sites from '../data/sites';
import { Link } from "react-router-dom";
function Sites() {
    const [site, setSite] = useState('');
    const { id } = useParams()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const foundSite = sites.find(site => site.id === parseInt(id));
        if (foundSite) {
            setSite(foundSite);
        }

        console.log(foundSite);
    }, [id]);


    useEffect(() => {
        // Проверяем, есть ли токен в локальном хранилище
        const token = localStorage.getItem('token');

        fetch('https://alfaapi-a6ba38f16d4e.herokuapp.com/check-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        })
            .then(response => response.json())
            .then(data => {
                if (data.isValidToken) {
                    // Токен актуален, продолжаем выполнение
                    setLoading(false);
                } else {
                    window.location.href = '/login';
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    };


    useEffect(() => {
        fetch(`https://alfaapi-a6ba38f16d4e.herokuapp.com/get-comments/${id}`)
            .then(response => response.json())
            .then(data => {
                setComments(data)
                console.log(data);
            })
            .catch(error => console.error('Ошибка при загрузке комментариев:', error));
    }, []);




    return (
        <>
            {loading ? (
                <div className="flex h-[100vh] justify-center items-center">Loading...</div> // Если данные загружаются, показываем заглушку
            ) : (
                <div className="content">
                    <div className='hidden bg-[#0000002] backdrop-blur-sm w-full h-[100vh] absolute'>
                        <div id='close' className='absolute right-10 top-10 bg-red'>
                            <PlusCircleIcon className='h-16 w-16 transform rotate-45' />
                        </div>
                    </div>
                    <div className='flex bg-gray-900'>
                        <div className='w-1/5 bg-gray-800 border border-gray-700 shadow-xl h-[100vh] flex flex-col  p-10 justify-between items-center'>
                            <h1 className='text-white font-bold'>
                                ADMIN<span className='text-primary-600'>PANEL</span>
                            </h1>
                            <ul className='text-white flex flex-col gap-4'>
                                <li>
                                    <a className='hover:text-primary-600 flex gap-2' href='/sites'>
                                        <HomeIcon className='w-6 h-6' />
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a className='hover:text-primary-600 flex gap-2' href='/sites'>
                                        <WindowIcon className='w-6 h-6' />
                                        Sites ({sites.length})
                                    </a>
                                </li>
                            </ul>
                            <ul className='text-white flex flex-col gap-3'>
                                <li>
                                    <a onClick={handleLogout} className='hover:text-primary-600 gap-2 flex items-center' href='#'>
                                        Выход
                                        <ArrowLeftEndOnRectangleIcon className='w-5 h-5' />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='w-4/5 text-white overflow-auto h-[100vh] p-10 gap-4 rounded-xl'>
                            <div className="f-full grid grid-cols-3 gap-2">
                                {sites.map((site, index) => (
                                    <div key={index} className="text-black bg-white shadow-xl p-4 rounded-xl">
                                        <div className="flex justify-between items-center mb-5">
                                            <div>
                                                <span className="font-bold rounded-full mr-4 p-4 bg-indigo-700 text-white">{site.id}</span>
                                                <span className="text-xl font-bold">{site.name}</span>
                                            </div>

                                            <div>
                                                <span className="opacity-50">{site.link}</span>
                                            </div>

                                        </div>

                                        <div className="flex gap-4 font-semibold underline mt-12">
                                            <Link className="flex gap-1 items-center" to={`/${site.id}`}>
                                                <PencilIcon className="w-5 h-5" />
                                                Moderation
                                            </Link>
                                            <a className="flex gap-1 items-center" href={`https://${site.link}`} target="_blank" rel="noopener noreferrer">
                                                <GlobeAltIcon className="w-5 h-5" />
                                                Open website
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>

    );
}

export default Sites;