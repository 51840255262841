const sites = [
    { id: 12, name : 'Хакасия информ', link: 'xakas.info' },
    { id: 13, name : 'Газета Новый Вестник', link: 'mv-kz.org' },
    { id: 14, name : 'gurk.kz', link: 'gurk-kz.info' },
    { id: 15, name : 'OSKEMEN', link: 'ockemen.info' },
    { id: 17, name : 'freehali.com', link: 'freehaii.com' },
    { id: 18, name : 'Брокер Трибунал', link: 'brokerstribunal.com' },
    { id: 19, name : 'Форекс рейтинг', link: 'forex-rating.info' },
    { id: 20, name : 'forex 4 free', link: 'forex2free.net' },
    { id: 21, name : 'Тейк профит', link: 'take-profits.org' },
    { id: 22, name : 'Zeramony', link: 'zeramonygourmet.com' },
    { id: 23, name : 'Отзовик', link: 'stablereview.com' },
    { id: 24, name : 'FX Team', link: 'fxteam.info' },
    { id: 25, name : 'Forex Up', link: 'forexx-up.com' },
    { id: 26, name : 'Плюс минус', link: 'plusiiminus.com' },
    { id: 27, name : 'Финотзывы', link: 'fynotzyvy.com' },
]

export default sites;

// app-airgram.com
// sleshpage.com
// doc-clickup.com
// tackade.com
// xakas.info
// mv-kz.org
// gurk-kz.info
// ockemen.info
// top-newkz.info
// freehaii.com
// brokerstribunal.com
// forex-rating.info
// forex2free.net

// take-profits.org
// zeramonygourmet.com
// vsjapravda.net